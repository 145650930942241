{
  "A valid number must have only numbers and can contein # and *": "A valid number must have only numbers and can contein # and *",
  "About": "About",
  "ACCEPTED": "ACCEPTED",
  "Accounts": "Accounts",
  "activate account": "activate account",
  "activate your account": "activate your account",
  "active until": "active until",
  "Active": "Active",
  "Activity": "Activity",
  "add a new user": "add a new user",
  "Add a number to save contact": "Add a number to save contact",
  "add new number": "add new number",
  "add number": "add number",
  "Add picture": "Add picture",
  "Add your first contact": "Add your first contact",
  "Add your first presence": "Add your first presence",
  "add_media": "Add media",
  "Address Incomplete": "Address Incomplete",
  "Address": "Address",
  "addressline1": "Address line 1",
  "addressline2": "Address line 2",
  "advanced search": "advanced search",
  "advanced": "Advanced",
  "all activity": "all activity",
  "all calls": "all calls",
  "all contacts": "All contacts",
  "All files": "All files",
  "An email is on its way to you to reset your password": "An email is on its way to you to reset your password",
  "and video conferences. Select a contact": "and video conferences. Select a contact",
  "apply": "apply",
  "april": "april",
  "Are you sure you want to delete this contact?": "Are you sure you want to delete this contact?",
  "Are you sure you want to delete this number?": "Are you sure you want to delete this number?",
  "Are you sure you want to delete this presence record?": "Are you sure you want to delete this presence record?",
  "Are you sure you want to delete this voicemail?": "Are you sure you want to delete this voicemail?",
  "Are you sure you wish to unassign the number": "Are you sure you wish to unassign the number",
  "Are you sure?": "Are you sure?",
  "Ask first": "Ask first",
  "Assigned To": "Assigned To",
  "Audio & Video": "Audio & video",
  "Audio saved": "Audio saved",
  "audio_call_from": "Audio call from {name} {number}",
  "audio": "audio",
  "august": "august",
  "authenticating": "authenticating",
  "Authentication Error": "Authentication Error",
  "authorization needed": "authorization needed",
  "Available numbers": "Available numbers",
  "Away": "Away",
  "Bad Media Description": "Bad Media Description",
  "basic information": "basic information",
  "birthday": "Birthday",
  "Birthday": "Birthday",
  "block": "block",
  "Business phone": "Business phone",
  "Busy here": "Busy",
  "Busy": "Busy",
  "call history": "call history",
  "Call is being forwarded": "Call is being forwarded",
  "Call is being transferred": "Call is being transferred",
  "Call transferred": "Call transferred",
  "call type": "call type",
  "call with": "call with",
  "Call": "Call",
  "Called From": "Called From",
  "calling from": "calling from",
  "Calling": "Calling",
  "calls from": "calls from",
  "calls to": "calls to",
  "Calls, messages, and voicemail from": "Calls, messages, and voicemail from",
  "Calls": "Calls",
  "Camera": "Camera",
  "Cancel Conference": "Cancel Conference",
  "Cancel": "Cancel",
  "Canceled": "Canceled",
  "Cancelled": "Cancelled",
  "Change": "change",
  "check your device inputs and outputs": "check your device inputs and outputs",
  "City": "City",
  "close": "Close",
  "Collaborate with calls, messages,": "Collaborate with calls, messages,",
  "Company Directory": "Company Directory",
  "company name": "Company name",
  "company position": "Company position",
  "Company": "Company",
  "Conference with": "Conference with {name}",
  "Conference": "Conference",
  "confirm your password": "confirm your password",
  "Connecting": "Connecting",
  "Console": "Console",
  "contact_no_email": "This contact doesn't have an email",
  "Contacts must have at least one number": "Contacts must have at least one number",
  "contacts": "contact | contacts",
  "Contacts": "Contacts",
  "Continue anyway": "Continue anyway",
  "create account": "create account",
  "create an account": "create an account",
  "Create contact": "Create contact",
  "Create Contact": "Create Contact",
  "Create one": "Create one",
  "Create Presence": "Create Presence",
  "create your account": "create your account",
  "create_new_message_info": "Create a new message by pressing the button below.",
  "create_new_message": "Create new message",
  "Creating...": "Creating...",
  "customer": "customer",
  "december": "december",
  "decline": "decline",
  "declined conference": "declined conference",
  "DECLINED": "DECLINED",
  "default number": "default number",
  "default": "default",
  "Delete contact": "Delete contact",
  "Delete number": "Delete number",
  "Delete presence record": "Delete presence record",
  "Delete voicemail": "Delete voicemail",
  "Delete": "Delete",
  "description": "description",
  "deselect all": "Deselect All",
  "Details": "Details",
  "Device is offline": "Device is offline",
  "Devices saved successfully": "Devices saved successfully",
  "dial a number or drag a contact": "Dial a number or drag a Contact",
  "Dial a number to call": "Dial a number to call",
  "Dial a number to initiate conference": "Dial a number to initiate conference",
  "Dial a number to transfer": "Dial a number to transfer",
  "Dial": "Dial",
  "Dialog Error": "Dialog Error",
  "Dialpad": "Dialpad",
  "directory": "directory",
  "Directory": "Directory",
  "disable": "disable",
  "Disable": "Disable",
  "display name": "display name",
  "display photo": "display photo",
  "Do Not Disturb": "Do Not Disturb",
  "Do you want to continue?": "Do you want to continue?",
  "don't have an account": "don't have an account",
  "Dont have an account?": "Don't have an account?",
  "Edit contact": "Edit Contact",
  "Edit Contact": "Edit Contact",
  "edit group": "Edit group",
  "edit number": "edit number",
  "Edit Presence": "Edit Presence",
  "Edit User": "Edit User",
  "edit": "edit",
  "email address": "email address",
  "email already exists": "E-mail already exists",
  "email": "email",
  "Email": "Email",
  "Enable": "Enable",
  "end and accept": "end & accept",
  "English (UK)": "English (UK)",
  "English (US)": "English (US)",
  "enter a number": "Enter a number",
  "Enter account email": "Enter account email",
  "enter code": "enter code",
  "Enter invite code": "Enter invite code",
  "Enter your new password again": "Enter your new password again",
  "Enter your new password": "Enter your new password",
  "Enter your password again": "Enter your password again",
  "Enter your password": "Enter your password",
  "Error on contact creating, review inputs and try again!": "Error on contact creating, review inputs and try again!",
  "Error on number creating, review inputs and try again!": "Error on number creating, review inputs and try again!",
  "Error on profile creating, review inputs and try again!": "Error on profile creating, review inputs and try again!",
  "Error on profile creating, review URL input and try again!": "Error on profile creating, review URL input and try again!",
  "Error on user creating, review inputs and try again!": "Error on user creating, review inputs and try again!",
  "Expires": "Expires",
  "Failed to connect to the server": "Failed to connect to the server with {number}",
  "Failed to import Outlook contacts": "Failed to import Outlook contacts",
  "Failed to load this voicemail": "Failed to load this voicemail",
  "Failed to save contact": "Failed to save contact",
  "Failed to start the conference with": "Failed to start the conference with {name}",
  "Failed to transfer": "Failed to transfer",
  "Favorites": "Favorites",
  "february": "february",
  "filter": "filter | filters",
  "First name": "First name",
  "first_and_last_name": "Please ensure the User Name ({name}) contains your first and last name.",
  "Forbidden. Review your settings": "Forbidden {number}. Review your settings",
  "Forgot Password?": "Forgot Password?",
  "Forgot your password?": "Forgot your password?",
  "forgot your password": "forgot your password",
  "from date": "from date",
  "from": "from",
  "From": "From",
  "Full name": "Full name",
  "Giving it a try": "Giving it a try",
  "Groups": "Groups",
  "Group not found": "Group not found",
  "have an email invite": "have an email invite",
  "hide": "Hide",
  "history": "history",
  "hold and accept": "hold & accept",
  "Hold": "Hold",
  "Home phone": "Home phone",
  "Home": "Home",
  "home": "home",
  "hostname": "hostname",
  "hour": "hour | hours",
  "Ignore": "Ignore",
  "Image files": "Image files",
  "import apple": "import apple",
  "import contacts": "import contacts",
  "import outlook": "import outlook",
  "imported": "imported",
  "incoming call": "incoming call",
  "Incoming Conference": "Incoming Conference",
  "Incoming meeting from": "Incoming meeting from",
  "Incompatible SDP": "Incompatible SDP",
  "Invite new User": "Invite new User",
  "invite successfully processed": "Invite successfully processed",
  "Invite to conference": "Invite to conference",
  "Invite": "Invite",
  "invited": "invited",
  "is currently being transferred": " is currently being transferred",
  "it appears that you don’t have any messages": "it appears that you don’t have any messages",
  "it appears that you don’t have any voicemail": "it appears that you don’t have any voicemail",
  "january": "january",
  "Job title": "Job title",
  "july": "july",
  "june": "june",
  "label": "Label",
  "Language": "Language | Languages",
  "last name": "Last name",
  "Lasted": "Lasted",
  "Load more": "Load more",
  "loading conference": "loading conference",
  "Loading Profile": "Loading Profile",
  "loading": "loading",
  "Log In": "Log In",
  "Looks like you are missing some presence.": "Looks like you are missing some presence.",
  "make a call": "make a call",
  "manage numbers": "manage numbers",
  "march": "march",
  "may": "may",
  "Meeting with": "Meeting with",
  "Meeting": "Meeting",
  "meetings": "Meetings",
  "message": "message",
  "messages": "messages",
  "Microphone Audio": "Microphone Audio",
  "microphone": "Microphone",
  "minute": "minute | minutes",
  "missed call": "missed call",
  "missed_calls": "missed calls",
  "Missed": "Missed",
  "Mobile phone": "Mobile phone",
  "mobile": "mobile",
  "most recent calls": "most recent calls",
  "Mute": "Mute",
  "My Activity": "My Activity",
  "name": "name | names",
  "Name": "Name",
  "New contact name": "New contact name",
  "New contact number": "New contact number",
  "New contact or group": "Create new contact or group",
  "New contact": "New contact",
  "New group": "New group",
  "New meeting": "New meeting",
  "new number": "new number",
  "new_message": "New message",
  "next": "Next",
  "No ACK": "An incoming INVITE was replied with 2XX status code, but no ACK was received",
  "No activity yet description": "When you start or receive calls, messages, and meetings, <br>your activity will appear here.",
  "No activity yet": "No activity yet",
  "No Answer": "No Answer",
  "no call history description": "It appears that you don't have any call history. Make a call and fill this section in.",
  "no call history": "no call history",
  "no calls found": "no calls found",
  "No calls yet description": "When you start or receive calls, <br>they will appear here.",
  "No calls yet": "No calls yet",
  "No camera detected": "No camera detected",
  "no contacts 1": "No contacts",
  "no contacts description 1": "Looks like you are missing some contacts.",
  "no contacts description 2": "Not to worry, you can either import your contacts by clicking the button below or creating a new one.",
  "no contacts": "no contacts found",
  "no meeting yet": "no meeting yet",
  "No meetings yet description": "When you send or receive meetings, <br>they will appear here.",
  "No meetings yet": "No meetings yet",
  "No messages yet description": "When you send or receive messages, <br>they will appear here.",
  "No messages yet": "No messages yet",
  "No microphone detected": "No microphone detected",
  "no new notifications": "no new notifications",
  "no new voicemail": "no new voicemail",
  "No number selected to make a call": "No number selected to make a call",
  "No numbers registered": "No numbers registered",
  "no phone numbers": "no phone numbers",
  "No presence": "No presence",
  "No speaker detected": "No speaker detected",
  "no voicemails description": "It appears that you don't have any voicemails.",
  "No voicemails yet description": "When you receive voicemails, <br>it will appear here.",
  "No voicemails yet": "No voicemails yet",
  "no voicemails": "no voicemails",
  "no_message_found_detailed": "It doesn't look like you have sent any messages.",
  "no_message_found": "No message found",
  "No": "No",
  "Not Found": "Not Found",
  "not informed": "not informed",
  "Nothing to see here—yet!": "Nothing to see here—yet!",
  "november": "november",
  "Number changes saved": "Number changes saved",
  "Number need a description": "Number need a description",
  "Number nickname": "Number nickname",
  "registered successfully": "registered successfully",
  "Number unregistered": "Number {number} unregistered",
  "number": "number | numbers",
  "Number": "Number",
  "numbers management": "numbers management",
  "numbers nickname": "Numbers nickname",
  "Numbers": "Numbers",
  "october": "october",
  "off": "Off",
  "Offline": "Offline",
  "OK": "OK",
  "on": "On",
  "One of the numbers has no name. Naming them is highly recommended to improve your experience.": "One of the numbers has no name. Naming them is highly recommended to improve your experience.",
  "Ongoing": "Ongoing",
  "Online": "Online",
  "Open user menu": "Open user menu",
  "or invite a team member to start.": "or invite a team member to start.",
  "or": "or",
  "Other": "Other",
  "other": "other",
  "Outlook Contacts": "Outlook Contacts",
  "outside your directory will appear here.": "outside your directory will appear here.",
  "participants": "participants",
  "password reset": "password reset",
  "password": "password",
  "PENDING": "PENDING",
  "phone": "phone | phones",
  "Phone": "Phone",
  "photo": "photo",
  "please enter a city": "please enter a city",
  "please enter a company name": "please enter a company name",
  "please enter a full name": "please enter a full name",
  "please enter a hostname": "please enter a hostname",
  "please enter a last name": "please enter a last name",
  "Please enter a name and valid email address": "Please enter a name and valid email address",
  "please enter a name": "please enter a name",
  "please enter a number": "please enter a number",
  "please enter a password": "please enter a password",
  "please enter a position": "please enter a position",
  "Please enter a valid email address": "Please enter a valid email address",
  "please enter a valid URL": "please enter a valid URL",
  "please enter an address": "please enter an address",
  "please enter an URL": "please enter an URL",
  "please enter the code you received with your email invite to Switch": "please enter the code you received with your email invite to Switch",
  "Please enter your account email and we'll email you a link to reset your password": "Please enter your account email and we'll email you a link to reset your password",
  "please enter your account email and we’ll email you a link to reset your password": "please enter your account email and we’ll email you a link to reset your password",
  "Please enter your email address and password": "Please enter your email address and password",
  "Please enter your email address": "Please enter your email address",
  "Please enter your invite code": "Please enter your invite code",
  "Please enter your password": "Please enter your password",
  "Please fill a type": "Please fill a type",
  "Please fill the email": "Please fill the email",
  "Please fill the first name": "Please fill the first name",
  "Please fill the full name": "Please fill the full name",
  "Please type a number": "Please type a number",
  "Please, use smaller images": "Please, use smaller images",
  "Portuguese": "Portuguese",
  "Position": "Position",
  "post": "Post",
  "powered by": "powered by",
  "previous": "Previous",
  "Profile picture": "Profile picture",
  "Profile": "Profile",
  "Received a request/response that should have SDP body but did not": "Received a request/response that should have SDP body but did not",
  "Received From": "Received From",
  "Recent": "Recent",
  "reception console": "reception console",
  "Record": "Record",
  "Redirected": "Redirected",
  "register a number": "register a number",
  "register your first number": "register your first number",
  "Registered": "Registered",
  "Registration": "Registration",
  "Rejected": "Rejected",
  "remaining_message": "{value} characters remaining",
  "remember me": "remember me",
  "remove number": "remove number",
  "Remove User": "Remove User",
  "Remove": "Remove",
  "Request Timeout": "Request timeout",
  "reset password": "reset password",
  "reset your password": "reset your password",
  "Retry": "Retry",
  "ringing on": "ringing on",
  "Ringing": "Ringing",
  "RTP Timeout": "RTP Timeout",
  "Save changes to apply": "Save changes to apply",
  "Save changes to remove number": "Save changes to remove number",
  "save": "save",
  "Save": "save",
  "search n contacts": "search {value} contact | search {value} contacts",
  "Search n messages": "Search {value} messages",
  "Search...": "Search...",
  "Search": "Search",
  "searching": "searching",
  "second": "second | seconds",
  "Select a microphone": "Select a microphone",
  "select a number to view previous calls and voicemail": "select a number to view previous calls and voicemail",
  "select a number": "select a number",
  "Select a number": "Select a number",
  "Select a speaker": "Select a speaker",
  "Select a type": "Select a type",
  "select account": "select account",
  "Select all": "Select all",
  "Select an camera": "Select an camera",
  "Select an microphone": "Select an microphone",
  "Select an speaker": "Select an speaker",
  "Selected": "Selected",
  "Send": "Send",
  "september": "september",
  "set and update your current settings": "set and update your current settings",
  "Set default": "Set default",
  "Settings": "Settings",
  "sign in to your {host} account": "sign in to your {host} account",
  "Sign in to your account": "Sign in to your account",
  "sign in": "sign in",
  "Sign Out": "Sign Out",
  "signed in as": "signed in as",
  "Something went wrong. Check your connection and try again.": "Something went wrong. Check your connection and try again.",
  "Spanish": "Spanish",
  "Speaker": "Speaker",
  "Starred": "Starred",
  "State": "State",
  "Status": "Status",
  "Stop record": "Stop record",
  "Stop": "Stop",
  "Support": "Support",
  "Teams": "Teams",
  "Terminated": "Terminated",
  "Test speaker": "Test speaker",
  "The number has been unlinked to the user.": "The number has been unlinked to the user.",
  "There was an error, try again": "There was an error, try again",
  "This action can't be undone.": "This action can't be undone.",
  "this phone number wont receive phone calls": "this phone number wont receive phone calls",
  "this phone will be the default option to connect": "this phone will be the default option to connect",
  "this section gives you the ability to edit your phone number": "this section gives you the ability to edit your phone number.",
  "Time": "Time",
  "to date": "to date",
  "To": "To",
  "Today": "Today",
  "token expired, sign in again": "Token expired, sign in again",
  "transfer accepted": "Transfer accepted",
  "Transfer now": "Transfer now",
  "Transfer": "Transfer",
  "Type": "Type",
  "Unassign number": "Unassign number",
  "Unassign": "Unassign",
  "Unauthorized. Check hostname and password": "Unauthorized {number}. Check hostname and password",
  "Unavailable": "Unavailable",
  "Unhold": "Unhold",
  "Unmute": "Unmute",
  "Unnamed number": "Unnamed number",
  "Unregistered": "Unregistered",
  "URL": "URL",
  "User Denied Media Access": "User Denied Media Access",
  "User removed.": "User removed.",
  "User saved": "User saved",
  "User type": "User type",
  "user": "user | users",
  "Users management": "Users management",
  "Using as": "Using as",
  "verify": "verify",
  "Version": "Version",
  "video_call_from": "Video call from {name} {number}",
  "video": "video",
  "view detail": "view detail",
  "view details": "View details",
  "Voicemail": "Voicemail",
  "we don't recognize this device. Please check your email to authorize your access and try to sign in again": "we don't recognize this device. Please check your email to authorize your access and try to sign in again",
  "Webcam Preview": "Webcam Preview",
  "Welcome to": "Welcome to",
  "welcome": "welcome",
  "work": "Work",
  "Workspaces": "Workspaces",
  "would like to start a meeting with you!": "would like to start a meeting with you!",
  "write_a_message_to": "Write a message to {name}",
  "write_a_message": "Write a message...",
  "Yes": "Yes",
  "Yesterday": "Yesterday",
  "You are about to remove": "You are about to remove",
  "You are not connected": "You are not connected",
  "You need to have a number registered in order to make a call": "You need to have a number registered in order to make a call",
  "You will  receive alerts for incoming calls for the number": "You will receive alerts for incoming calls for the number",
  "You will not receive alerts for incoming calls for the number": "You will not receive alerts for incoming calls for the number",
  "you won't be notified of incoming calls": "you won't be notified of incoming calls",
  "you": "you",
  "Your changes were ignored": "Your changes were ignored",
  "your email or password is incorrect": "Your email or password is incorrect",
  "your number": "Your number",
  "your password was successfully changed": "your password was successfully changed",
  "your password": "Your password",
  "your passwords are different": "your passwords are different",
  "Select an option": "Select an option",
  "Favorite": "Favorite",
  "This is the start of something new.": "This is the start of something new.",
  "Your messages, calls, and video meetings": "Your messages, calls, and video meetings",
  "will appear here.": "will appear here.",
  "Info": "Info",
  "Type your message here": "Type your message here",
  "Users": "Users",
  "User edited successfully.": "User edited successfully.",
  "User invited.": "User invited.",
  "enabled": "enabled",
  "Changed password": "Changed password",
  "Password are required": "Password are required",
  "Confirm password are required": "Confirm password are required",
  "Password does not match": "Password does not match",
  "Password requires a lowercase character": "Password requires a lowercase character",
  "Password requires a uppercase character": "Password requires a uppercase character",
  "Password requires one digit": "Password requires one digit",
  "Password requires at least 8 digits": "Password requires at least 8 digits",
  "Create Password": "Create Password",
  "Home Page": "Home Page",
  "Login": "Login",
  "Dial a number to transfer to": "Dial a number to transfer to",
  "has been unregistered": "has been unregistered",
  "Dial a number to make the call": "Dial a number to make the call",
  "registration failed": "registration failed",
  "No active number": "No active number",
  "actived": "actived",
  "Profile updated": "Profile updated",
  "disabled": "disabled",
  "Ended": "Ended",
  "lasted": "lasted",
  "You don’t have any": "You don’t have any",
  "contacts in your":"contacts in your",
  "directory. Add contacts": "directory. Add contacts",
  "to your team.": "to your team.",
  "Group successfully created.": "Group successfully created.",
  "new-message-group": "New message in {group}",
  "new-message-chat": "New message from {user}",
  "{name} is not a {appName} user.": "{name} is not a {appName} user.",
  "Invite {name} to join your team": "Invite {name} to join your team",
  "to enable messaging and video meetings.": "to enable messaging and video meetings."
}
