{
  "A valid number must have only numbers and can contein # and *": "Un número válido debe tener solo números y puede contener # y *",
  "About": "Sobre",
  "ACCEPTED": "ACEPTADA",
  "Accounts": "Cuentas",
  "activate account": "activar cuenta",
  "activate your account": "active su cuenta",
  "active until": "activo hasta",
  "Active": "Activo",
  "Activity": "Actividad",
  "add a new user": "añadir un nuevo usuario",
  "Add a number to save contact": "Agrega un número para guardar el contacto",
  "Add new Group": "Añadir grupo",
  "add new number": "añadir nuevo número",
  "add number": "añade un número",
  "Add picture": "Añade una foto",
  "Add your first contact": "Agrega tu primer contacto",
  "Add your first presence": "Agrega tu primera presencia",
  "add_media": "Add media",
  "Address Incomplete": "Dirección incompleta",
  "Address": "Dirección",
  "addressline1": "Línea de dirección 1",
  "addressline2": "Línea de dirección 2",
  "advanced search": "búsqueda avanzada",
  "advanced": "Avanzada",
  "all activity": "toda la actividad",
  "all calls": "todas las llamadas",
  "all contacts": "Todos los contactos",
  "All files": "Todos los archivos",
  "An email is on its way to you to reset your password": "Un correo electrónico está en camino para restablecer su contraseña.",
  "and video conferences. Select a contact": "y videoconferencias. Seleccione un contacto",
  "apply": "solicitar",
  "april": "abril",
  "Are you sure you want to delete this contact?": "¿Estás seguro de que deseas eliminar este contacto?",
  "Are you sure you want to delete this number?": "¿Estás seguro de que quieres eliminar este número?",
  "Are you sure you want to delete this presence record?": "¿Está seguro de que desea eliminar este registro de presencia?",
  "Are you sure you want to delete this voicemail?": "¿Estás seguro de que quieres eliminar este mensaje de voz?",
  "Are you sure you wish to unassign the number": "¿Estás seguro de que deseas anular la asignación del número",
  "Are you sure?": "¿Estás seguro?",
  "Ask first": "Pregunta primero",
  "Assigned To": "Asignado a",
  "Audio & Video": "Audio & Video",
  "Audio saved": "Audio guardado",
  "audio_call_from": "Audio llamada {name} {number}",
  "audio": "audio",
  "august": "agosto",
  "authenticating": "autenticando",
  "Authentication Error": "Error de autenticación",
  "authorization needed": "autorización necesaria",
  "Available numbers": "Números disponibles",
  "Away": "Fuera",
  "Bad Media Description": "Descripción de medios incorrecta",
  "basic information": "información básica",
  "birthday": "Cumpleaños",
  "Birthday": "Cumpleaños",
  "block": "bloque",
  "Business phone": "Teléfono de empresa",
  "Busy here": "Ocupado",
  "Busy": "Ocupado",
  "call history": "historial de llamadas",
  "Call is being forwarded": "La llamada se está reenviando",
  "Call is being transferred": "Llamada transferida",
  "Call transferred": "Llamada transferida",
  "call type": "tpo de llamada",
  "call with": "llamar con",
  "Call": "Llamada",
  "Called From": "Llamado desde",
  "calling from": "llamando desde",
  "Calling": "Llamando",
  "calls from": "llamadas de",
  "calls to": "llamadas a",
  "Calls, messages, and voicemail from": "Las llamadas, los mensajes y el correo de voz desde",
  "Calls": "Llamadas",
  "Camera": "Camera",
  "Cancel Conference": "Cancelar conferencia",
  "Cancel": "Cancelar",
  "Canceled": "Cancelado",
  "Cancelled": "Cancelado",
  "Change": "Cambio",
  "check your device inputs and outputs": "verifique las entradas y salidas de su dispositivo",
  "City": "Ciudad",
  "close": "Cerrar",
  "Collaborate with calls, messages,": "Colabora con llamadas, mensajes,",
  "Company Directory": "Conexiones",
  "company name": "Nombre de empresa",
  "company position": "Posición la compañía",
  "Company": "Empresa",
  "Conference with": "Conferencia con {name}",
  "Conference": "Conferencia",
  "confirm your password": "confirmar la contraseña",
  "Connecting": "Conectando",
  "Console": "Consola",
  "contact_no_email": "This contact doesn't have an email",
  "Contacts must have at least one number": "Los contactos deben tener al menos un número",
  "contacts": "contacto | contactos",
  "Contacts": "Contactos",
  "Continue anyway": "Continuar de todas maneras",
  "create account": "crear una cuenta",
  "create an account": "crea una cuenta",
  "Create contact": "Crear contacto",
  "Create Contact": "Crear contacto",
  "Create one": "Crear una",
  "Create Presence": "Crear Presencia",
  "create your account": "crear tu cuenta",
  "create_new_message_info": "Cree un mensaje nuevo presionando el botón de abajo.",
  "create_new_message": "Crear mensaje nuevo",
  "Creating...": "Creando...",
  "customer": "cliente",
  "december": "diciembre",
  "decline": "colgar",
  "declined conference": "rechazó la conferencia",
  "DECLINED": "RECHAZADA",
  "default number": "número por defecto",
  "default": "defecto",
  "Delete contact": "Borrar contacto",
  "Delete number": "Eliminar número",
  "Delete presence record": "Eliminar registro de presencia",
  "Delete voicemail": "Eliminar correo de voz",
  "Delete": "Borrar",
  "description": "descripción",
  "deselect all": "Deseleccionar todo",
  "Details": "Detalles",
  "Device is offline": "El dispositivo está desconectado",
  "Devices saved successfully": "Dispositivos guardados correctamente",
  "dial a number or drag a contact": "Marcar un número o arrastrar un contacto",
  "Dial a number to call": "Marque un número para llamar",
  "Dial a number to initiate conference": "Marque un número para iniciar la conferencia",
  "Dial a number to transfer": "Marque un número para transferir",
  "Dial": "Marcar",
  "Dialog Error": "Error de diálogo",
  "Dialpad": "Teclado",
  "directory": "directorio",
  "Directory": "Directorio",
  "disable": "desactivar",
  "Disable": "Deshabilitar",
  "display name": "nombre",
  "display photo": "foto",
  "Do Not Disturb": "No Molestar",
  "Do you want to continue?": "¿Quieres continuar?",
  "don't have an account": "no tiene una cuenta",
  "Dont have an account?": "¿No tienes una cuenta?",
  "Edit contact": "Editar Contacto",
  "Edit Contact": "Editar Contacto",
  "edit group": "Editar grupo",
  "edit number": "editar número",
  "Edit Presence": "Editar presencia",
  "Edit User": "Editar Usuario",
  "edit": "editar",
  "email address": "correo electrónico",
  "email already exists": "El Email ya existe",
  "email": "correo",
  "Email": "Correo",
  "Enable": "Habilitar",
  "end and accept": "terminar & aceptar",
  "English (UK)": "Inglés (del Reino Unido)",
  "English (US)": "Inglés (EE. UU.)",
  "enter a number": "Ingrese un numero",
  "Enter account email": "Introduzca el correo electrónico de la cuenta",
  "enter code": "introduzca el código",
  "Enter invite code": "Introduzca el código de invitación",
  "Enter your new password again": "Introduzca su nueva contraseña nuevamente",
  "Enter your new password": "Introduzca su nueva contraseña",
  "Enter your password again": "Introduzca su contraseña nuevamente",
  "Enter your password": "Introduzca su contraseña",
  "Error on contact creating, review inputs and try again!": "Error al crear el contacto, revise las entradas y vuelva a intentarlo",
  "Error on number creating, review inputs and try again!": "Error al crear el número, revise las entradas y vuelva a intentarlo",
  "Error on profile creating, review inputs and try again!": "Error al crear el perfil, revise las entradas y vuelva a intentarlo",
  "Error on profile creating, review URL input and try again!": "Error al crear el perfil, revise la entrada de la URL y vuelva a intentarlo",
  "Error on user creating, review inputs and try again!": "Error al crear el usuario, revise las entradas y vuelva a intentarlo",
  "Expires": "Expira",
  "Failed to connet to the server": "No se pudo conectar al servidor con {number}",
  "Failed to import Outlook contacts": "Fallo en la importación de contactos de Outlook",
  "Failed to load this voicemail": "No se pudo cargar este mensaje de voz.",
  "Failed to save contact": "No se pudo guardar el contacto",
  "Failed to start the conference with": "Fallo al iniciar la conferencia con {name}",
  "Failed to transfer": "No se pudo transferir",
  "Favorites": "Favoritos",
  "february": "febrero",
  "filter": "filtrar | filtros",
  "First name": "Nombre de pila",
  "first_and_last_name": "Por favor, asegúrese de que el Nombre de usuario ({name}) contenga nombre y apellido.",
  "Forbidden. Review your settings": "{number} Revise su configuración",
  "Forgot Password?": "¿Has olvidado tu contraseña?",
  "Forgot your password?": "¿Olvidaste tu contraseña?",
  "forgot your password": "olvidaste tu contraseña",
  "from date": "partir de la fecha",
  "from": "de",
  "From": "De",
  "Full name": "Nombre completo",
  "Giving it a try": "Dándole una oportunidad",
  "Groups": "Grupos",
  "Group not found": "Grupo no encontrado",
  "have an email invite": "tiene una invitación por correo electrónico",
  "hide": "Esconder",
  "history": "historia",
  "hold and accept": "espere & aceptar",
  "Hold": "Esperar",
  "Home phone": "Teléfono de casa",
  "home": "Casa",
  "hostname": "hostname",
  "hour": "hora | horas",
  "Ignore": "Ignorar",
  "Image files": "Archivos de imagen",
  "import apple": "importar apple",
  "import contacts": "importar contactos",
  "import outlook": "importar contatos",
  "imported": "importado",
  "incoming call": "llamada entrante",
  "Incoming Conference": "Conferencia Entrante",
  "Incoming meeting from": "Reunión de",
  "Incompatible SDP": "SDP incompatible",
  "Invite new User": "Invitar al nuevo Usuario",
  "invite successfully processed": "Invite procesado con éxito",
  "Invite to conference": "Invitar a la conferencia",
  "Invite": "Invitación",
  "invited": "invitado",
  "is currently being transferred": " está siendo transferido actualmente",
  "it appears that you don’t have any messages": "parece que no tienes ningún mensaje",
  "it appears that you don’t have any voicemail": "parece que no tienes ningún buzón de voz",
  "january": "enero",
  "Job title": "Título profesional",
  "july": "julio",
  "june": "junio",
  "label": "Etiqueta",
  "Language": "Idioma | Idiomas",
  "last name": "Nombre de familia",
  "Lasted": "Duró",
  "Load more": "Carga más",
  "loading conference": "cargando conferencia",
  "Loading Profile": "Cargando perfil",
  "loading": "cargando",
  "Log In": "Iniciar sesión",
  "Looks like you are missing some presence.": "Parece que te falta algo de presencia.",
  "make a call": "haz una llamada",
  "manage numbers": "administrar números",
  "march": "marzo",
  "may": "mayo",
  "Meeting with": "Reunión con",
  "Meeting": "Reunión",
  "meetings": "Reunións",
  "message": "mensaje",
  "messages": "mensajes",
  "Microphone Audio": "Sonido del micrófono",
  "microphone": "Micrófono",
  "minute": "minuto | minutos",
  "missed call": "llamada perdida",
  "missed_calls": "llamadas perdidas",
  "Missed": "Perdida",
  "Mobile phone": "Teléfono móvil",
  "mobile": "móvil",
  "most recent calls": "llamadas más recientes",
  "Mute": "Mudo",
  "My Activity": "Mi actividad",
  "name": "nombre | nombres",
  "Name": "Nombre",
  "New contact name": "Nuevo nombre de contacto",
  "New contact number": "Nuevo número de contacto",
  "New contact or group": "Crear nuevo contacto o grupo",
  "New contact": "Nuevo contacto",
  "New group": "Nuevo grupo",
  "New meeting": "Nueva reunión",
  "new number": "nuevo número",
  "new_message": "Nueva mensaje",
  "next": "Siguiente",
  "No ACK": "Un INVITE entrante fue respondido con el código de estado 2XX, pero no se recibió ACK",
  "No activity yet description": "Cuando inicie o reciba llamadas, mensajes y reuniones, <br>su actividad aparecerá aquí.",
  "No activity yet": "Aún no hay actividad",
  "No Answer": "Sin respuesta",
  "no call history description": "Parece que no tiene ninguna historia de llamadas. Haga una llamada y complete esta sección.",
  "no call history": "sin historial de llamadas",
  "no calls found": "no se encontraron llamadas",
  "No calls yet description": "Cuando inicie o reciba llamadas, <br> aparecerán aquí.",
  "No calls yet": "Aún no hay llamadas",
  "No camera detected": "No se detectó ninguna cámara",
  "no contacts 1": "Sin contactos",
  "no contacts description 1": "Parece que le faltan algunos contactos.",
  "no contacts description 2": "No se preocupe, puede importar sus contactos haciendo clic en el botón de abajo o creando uno nuevo.",
  "no contacts": "no se encontraron contactos",
  "no meeting yet": "no hay reunión",
  "No meetings yet description": "Cuando crea o se une a reuniones, <br> aparecerán aquí.",
  "No meetings yet": "Aún no hay reuniones",
  "No messages yet description": "Cuando envíe o reciba mensajes, <br> aparecerán aquí.",
  "No messages yet": "Aún no hay mensajes",
  "No microphone detected": "No se detectó ningún micrófono",
  "no new notifications": "no hay notificaciones nuevas",
  "no new voicemail": "no hay nuevo correo de voz",
  "No number selected to make a call": "No se ha seleccionado ningún número para realizar una llamada",
  "No numbers registered": "No hay números registrados",
  "no phone numbers": "sin números de teléfono",
  "No presence": "Sin presencia",
  "No speaker detected": "Ningún altavoz detectado",
  "no voicemails description": "Parece que no tienes mensajes de voz.",
  "No voicemails yet description": "Cuando reciba mensajes de voz, <br> aparecerá aquí.",
  "No voicemails yet": "Aún no hay mensajes de voz",
  "no voicemails": "sin mensajes de voz",
  "no_message_found_detailed": "No parece que hayas enviado ningún mensaje.",
  "no_message_found": "No se encontró mensaje",
  "No": "No",
  "Not Found": "No encontrado",
  "not informed": "no informado",
  "Nothing to see here—yet!": "No hay nada que ver aquí—¡todavía!",
  "november": "noviembre",
  "Number changes saved": "Se guardaron los cambios de número",
  "Number need a description": "Número necesita una descripción",
  "Number nickname": "Apodo numérico",
  "registered successfully": "registrado con éxito",
  "Number unregistered": "Número {number} no registrado",
  "number": "número | números",
  "Number": "Número",
  "numbers management": "gestión de números",
  "numbers nickname": "Identificación del número",
  "Numbers": "Números",
  "october": "octubre",
  "off": "Apag",
  "Offline": "Desconectado",
  "OK": "OK",
  "on": "Act.",
  "One of the numbers has no name. Naming them is highly recommended to improve your experience.": "Uno de los números no tiene nombre. Es muy recomendable nombrarlos para mejorar su experiencia.",
  "Ongoing": "En curso",
  "Online": "Activo",
  "Open user menu": "Abrir menú de usuario",
  "or invite a team member to start.": "o invite a un miembro del equipo a comenzar.",
  "or": "o",
  "other": "Otro",
  "Outlook Contacts": "Contactos de Outlook",
  "outside your directory will appear here.": "fuera de su directorio aparecerán aquí.",
  "participants": "participantes",
  "password reset": "restablecimiento de contraseña",
  "password": "contraseña",
  "PENDING": "PENDIENTE",
  "phone": "telefono | telefonos",
  "Phone": "Telefono",
  "photo": "foto",
  "please enter a city": "por favor ingrese una ciudad",
  "please enter a company name": "por favor, introduzca el nombre de la empresa",
  "please enter a full name": "por favor ingrese un nombre completo",
  "please enter a hostname": "por favor, introduzca un hostname",
  "please enter a last name": "por favor, introduzca un nombre de familia",
  "Please enter a name and valid email address": "Ingrese un nombre y una dirección de correo electrónico válida",
  "please enter a name": "por favor, introduzca un nombre",
  "please enter a number": "por favor, introduzca un número",
  "please enter a password": "por favor, introduzca una contraseña",
  "please enter a position": "por favor, introduzca una posición",
  "Please enter a valid email address": "Por favor, introduce una dirección de correo electrónico válida",
  "please enter a valid URL": "por favor, introduzca una URL válida",
  "please enter an address": "por favor, introduzca una dirección",
  "please enter an URL": "por favor, introduzca una URL",
  "please enter the code you received with your email invite to Switch": "por favor introduzca el código que recibió con su correo electrónico de invitación a Switch",
  "Please enter your account email and we'll email you a link to reset your password": "Ingrese el correo electrónico de su cuenta y le enviaremos un enlace para restablecer su contraseña",
  "please enter your account email and we’ll email you a link to reset your password": "introduzca el correo electrónico de su cuenta y le enviaremos un enlace para restablecer su contraseña",
  "Please enter your email address and password": "Ingrese su dirección de correo electrónico y contraseña",
  "Please enter your email address": "Por favor, introduzca su dirección de correo electrónico",
  "Please enter your invite code": "Por favor, introduzca su código de invitación",
  "Please enter your password": "Por favor, introduzca su contraseña",
  "Please fill a type": "Por favor complete un tipo",
  "Please fill the email": "Por favor complete el correo electrónico",
  "Please fill the full name": "Por favor complete el nombre completo",
  "Please type a number": "Por favor ingrese un numero",
  "Please, use smaller images": "Por favor, use imágenes más pequeñas",
  "Portuguese": "Portugués",
  "Position": "Posición",
  "post": "Enviar",
  "powered by": "hecho por",
  "previous": "Anterior",
  "Profile picture": "Foto de perfil",
  "Profile": "Perfil",
  "Received a request/response that should have SDP body but did not": "Recibí una solicitud / respuesta que debería tener cuerpo SDP pero no",
  "Received From": "Recibido de",
  "Recent": "Reciente",
  "reception console": "consola de recepción",
  "Record": "Grabar",
  "Redirected": "Redirigido",
  "register a number": "registrar un número",
  "register your first number": "registra tu primer número",
  "Registered": "Registrado",
  "Registration": "Registro",
  "Rejected": "Rechazado",
  "remaining_message": "{value} caracteres restantes",
  "remember me": "recuérdame",
  "remove number": "quitar número",
  "Remove User": "Eliminar usuario",
  "Remove": "Retirar",
  "Request Timeout": "Pide tiempo fuera",
  "reset password": "restablecer la contraseña",
  "reset your password": "restablecer su contraseña",
  "Retry": "Rever",
  "ringing on": "sonando",
  "Ringing": "El sonar",
  "RTP Timeout": "Tiempo de espera de RTP",
  "Save changes to apply": "Guardar cambios para aplicar",
  "Save changes to remove number": "Guardar cambios para eliminar el número",
  "save": "salvar",
  "Save": "salvar",
  "search n contacts": "buscar {value} contacto | buscar {value} contactos",
  "Search n messages": "Buscar {value} mensajes",
  "Search...": "Buscar...",
  "Search": "Buscar",
  "searching": "buscando",
  "second": "segundo | segundos",
  "Select a microphone": "Seleccione un micrófono",
  "select a number to view previous calls and voicemail": "seleccione un número para ver las llamadas anteriores y el correo de voz",
  "select a number": "seleccionar un número",
  "Select a speaker": "Seleccionar un altavoz",
  "select account": "seleccionar cuenta",
  "Select all": "Seleccionar todo",
  "Select an camera": "Select an camera",
  "Select an microphone": "Seleccione un micrófono",
  "Select an speaker": "Seleccione un altavoz",
  "Selected": "Seleccionado",
  "Send": "Enviar",
  "september": "septiembre",
  "set and update your current settings": "establecer y actualizar su configuración actual",
  "Set default": "Defecto",
  "Settings": "Ajustes",
  "sign in to your {host} account": "inicia sesión en tu cuenta {host}",
  "Sign in to your account": "Inicia sesión en tu cuenta",
  "sign in": "iniciar sesión",
  "Sign Out": "Desconectar",
  "signed in as": "registrado como",
  "Something went wrong. Check your connection and try again.": "Algo salió mal. Verifique su conexión y vuelva a intentarlo.",
  "Spanish": "Español",
  "Speaker": "Alto parlante",
  "Starred": "Favoritos",
  "State": "Estado",
  "Status": "Estado",
  "Stop record": "Detener registro",
  "Stop": "Detener",
  "Support": "Apoyo",
  "Teams": "Equipos",
  "Terminated": "Terminado",
  "Test speaker": "Altavoz de prueba",
  "The number has been unlinked to the user.": "El número ha sido desvinculado al usuario.",
  "There was an error, try again": "Hubo un error, inténtalo de nuevo",
  "This action can't be undone.": "Esta acción no se puede deshacer.",
  "this phone number wont recevie phone calls": "este número de teléfono no recibe llamadas",
  "this phone will be the default option to connect": "este teléfono será la opción por defecto para conectarse",
  "this section gives you the ability to edit your phone number": "esta sección le brinda la posibilidad de editar su número de teléfono.",
  "Time": "Tiempo",
  "to date": "hasta la fecha",
  "To": "A",
  "Today": "Este Dia",
  "token expired, sign in again": "Token caducado, vuelve a iniciar sesión",
  "transfer accepted": "Transferencia aceptada",
  "Transfer now": "Transferir ahora",
  "Transfer": "Transferir",
  "Type": "Tipo",
  "Unassign number": "Desasignar número",
  "Unassign": "Desasignar",
  "Unauthorized. Check hostname and password": "No autorizado {number}. Verifique el host y la contraseña",
  "Unavailable": "Indisponible",
  "Unhold": "Reanudar",
  "Unmute": "Activar",
  "Unnamed number": "Número sin nombre",
  "Unregistered": "No registrado",
  "URL": "URL",
  "User Denied Media Access": "Acceso a medios denegado al usuario",
  "User removed.": "Usuario eliminado.",
  "User saved": "Usuario guardado",
  "User type": "tipo de usuario",
  "user": "usuario | usuarios",
  "Users management": "Gestión de usuarios",
  "Using as": "Usando como",
  "verify": "verificar",
  "Version": "Versión",
  "video_call_from": "Video llamada {name} {number}",
  "video": "vídeo",
  "view detail": "ver detalle",
  "view details": "Ver detalles",
  "Voicemail": "Mensaje de voz",
  "we don't recognize this device. Please check your email to authorize your access and try to sign in again": "no reconocemos este dispositivo. Revise su correo electrónico para autorizar su acceso e intente iniciar sesión nuevamente",
  "Webcam Preview": "Vista previa de la cámara web",
  "Welcome to": "¡Bienvenido a",
  "welcome": "bienvenido",
  "work": "Trabajo",
  "Workspaces": "Espacios de Trabajo",
  "would like to start a meeting with you!": "le gustaría iniciar una reunión contigo!",
  "write_a_message_to": "Escribe un mensaje a {name}",
  "write_a_message": "Escribe un mensaje...",
  "Yes": "Sí",
  "Yesterday": "Ayer",
  "You are about to remove": "Estás a punto de eliminar",
  "You are not connected": "Tu no estas conectado",
  "You need to have a number registered in order to make a call": "Es necesario tener un número registrado para poder hacer una llamada",
  "You will  receive alerts for incoming calls for the number": "Recibirás alertas de llamadas entrantes para el número",
  "You will not receive alerts for incoming calls for the number": "No recibirá alertas de llamadas entrantes para el número",
  "you won't be notified of incoming calls": "no se le notificará de las llamadas entrantes",
  "you": "usted",
  "Your changes were ignored": "Tus cambios fueron ignorados",
  "your email or password is incorrect": "Su correo electrónico o la contraseña es incorrecta",
  "your number": "Tu número",
  "your password was successfully changed": "tu contraseña ha sido exitosamente cambiada",
  "your password": "Su contraseña",
  "your passwords are different": "tus contraseñas son diferentes",
  "Select an option": "Seleccione una opción",
  "Favorite": "Favorito",
  "This is the start of something new.": "Este es el comienzo de algo nuevo.",
  "Your messages, calls, and video meetings": "Sus mensajes, llamadas y reuniones de video",
  "will appear here.": "aparecerán aquí.",
  "Info": "Info",
  "Type your message here": "Escribe tu mensaje aquí",
  "Users": "Usuarios",
  "User edited successfully.": "Usuario editado con éxito.",
  "User invited.": "Usuario invitado.",
  "enabled": "activado",
  "Changed password": "Contraseña cambiada",
  "Password are required": "Se requiere contraseña",
  "Confirm password are required": "Se requiere Confirmar contraseña",
  "Password does not match": "Las contraseñas no coinciden",
  "Password requires a lowercase character": "La contraseña requiere un carácter en minúscula",
  "Password requires a uppercase character": "La contraseña requiere un carácter en mayúscula",
  "Password requires one digit": "La contraseña requiere un dígito",
  "Password requires at least 8 digits": "La contraseña requiere al menos 8 dígitos",
  "Create Password": "Crear contraseña",
  "Home Page": "Página de inicio",
  "Login": "Login",
  "Dial a number to transfer to": "Marque un número para transferir",
  "has been unregistered": "ha perdido el registro",
  "Dial a number to make the call": "Marque un número para hacer la llamada",
  "registration failed": "registro fallido",
  "No active number": "Sin número activo",
  "actived": "activado",
  "Profile updated": "Perfil actualizado",
  "disabled": "desactivado",
  "Ended": "Terminada",
  "lasted": "duró",
  "You don’t have any": "Usted no tiene",
  "contacts in your":"ningún contacto",
  "directory. Add contacts": "en tu Directorio. Agrega",
  "to your team.": "contactos a su equipo.",
  "Group successfully created.": "Grupo creado con éxito.",
  "new-message-group": "Nuevo mensaje en {group}",
  "new-message-chat": " Nuevo mensaje de {user}",
  "{name} is not a {appName} user.": "{name} no es un usuario de {appName}.",
  "Invite {name} to join your team": "Invite a {name} a unirse a su equipo ",
  "to enable messaging and video meetings.": "para habilitar la mensajería y las videollamadas."
}
