{
  "A valid number must have only numbers and can contein # and *": "Um número válido deve conter apenas números e pode conter # e *",
  "About": "Sobre",
  "ACCEPTED": "ACEITA",
  "Accounts": "Contas",
  "activate account": "ativar conta",
  "activate your account": "ativar sua conta",
  "active until": "ativo até",
  "Active": "Ativo",
  "Activity": "Atividade",
  "add a new user": "Adicionar um novo usuário",
  "Add a number to save contact": "Adicione um número para salvar o contato",
  "Add new Group": "Adicionar grupo",
  "add new number": "adicionar novo número",
  "add number": "adicionar número",
  "Add picture": "Adicionar foto",
  "Add your first contact": "Adicione seu primeiro contato",
  "Add your first presence": "Adicione sua primeira presença",
  "add_media": "Agregar",
  "Address Incomplete": "Endereço Incompleto",
  "Address": "Endereço",
  "addressline1": "Endereço linha 1",
  "addressline2": "Endereço linha 2",
  "advanced search": "busca avançada",
  "advanced": "Avançada",
  "all activity": "atividades",
  "all calls": "todas as chamadas",
  "all contacts": "Todos os contatos",
  "All files": "Todos os arquivos",
  "An email is on its way to you to reset your password": "Um e-mail está a caminho para que você redefina sua senha",
  "and video conferences. Select a contact": "e videoconferências. Selecione um contato",
  "apply": "aplicar",
  "april": "abril",
  "Are you sure you want to delete this contact?": "Tem certeza que deseja remover este contato?",
  "Are you sure you want to delete this number?": "Tem certeza que deseja remover este número?",
  "Are you sure you want to delete this presence record?": "Tem certeza de que deseja excluir este registro de presença?",
  "Are you sure you want to delete this voicemail?": "Tem certeza que deseja excluir este correio de voz?",
  "Are you sure you wish to unassign the number": "Tem certeza de que deseja cancelar a atribuição do número",
  "Are you sure?": "Você tem certeza?",
  "Ask first": "Pergunte primeiro",
  "Assigned To": "Atribuído a",
  "Audio & Video": "Áudio & Vídeo",
  "Audio saved": "Áudio salvo",
  "audio_call_from": "Chamada de áudio {name} {number}",
  "audio": "áudio",
  "august": "agosto",
  "authenticating": "autenticando",
  "Authentication Error": "Erro de autenticação",
  "authorization needed": "autorização necessária",
  "Available numbers": "Números disponíveis",
  "Away": "Ausente",
  "Bad Media Description": "Descrição de mídia ruim",
  "basic information": "informação básica",
  "birthday": "Aniversário",
  "Birthday": "Aniversário",
  "block": "bloquear",
  "Business phone": "Telefone comercial",
  "Busy here": "Ocupado",
  "Busy": "Ocupado",
  "call history": "histórico de chamadas",
  "Call is being forwarded": "A chamada está sendo encaminhada",
  "Call is being transferred": "Chamada sendo transferida",
  "Call transferred": "Chamada transferida",
  "call type": "tipo de chamada",
  "call with": "chamada com",
  "Call": "Chamada",
  "Called From": "Chamado de",
  "calling from": "ligando de",
  "Calling": "Chamando",
  "calls from": "chamadas de",
  "calls to": "chamadas para",
  "Calls, messages, and voicemail from": "Chamadas, mensagens e correio de voz de",
  "Calls": "Chamadas",
  "Camera": "Camera",
  "Cancel Conference": "Cancelar Conferência",
  "Cancel": "Cancelar",
  "Canceled": "Cancelado",
  "Cancelled": "Cancelado",
  "Change": "Alterar",
  "check your device inputs and outputs": "verifique as entradas e saídas do seu dispositivo",
  "City": "Cidade",
  "close": "Fechar",
  "Collaborate with calls, messages,": "Colabore com chamadas, mensagens,",
  "Company Directory": "Conexões",
  "company name": "Nome da empresa",
  "company position": "Posição na empresa",
  "Company": "Empresa",
  "Conference with": "Conferência com {name}",
  "Conference": "Conferência",
  "confirm your password": "confirme sua senha",
  "Connecting": "Conectando",
  "Console": "Console",
  "contact_no_email": "Este contato não possui um email",
  "Contacts must have at least one number": "Os contatos devem ter pelo menos um número",
  "contacts": "contato | contatos",
  "Contacts": "Contatos",
  "Continue anyway": "Continuar mesmo assim",
  "create account": "criar conta",
  "create an account": "crie uma conta",
  "Create contact": "Criar Contato",
  "Create Contact": "Criar Contato",
  "Create one": "Criar uma",
  "Create Presence": "Criar Presença",
  "create your account": "crie sua conta",
  "create_new_message_info": "Crie uma nova mensagem pressionando o botão abaixo.",
  "create_new_message": "Criar nova mensagem",
  "Creating...": "Criando...",
  "customer": "cliente",
  "december": "dezembro",
  "decline": "desligar",
  "declined conference": "recusou a conferência",
  "DECLINED": "RECUSADA",
  "default number": "número padrão",
  "default": "padrão",
  "Delete contact": "Excluir contato",
  "Delete number": "Excluir número",
  "Delete presence record": "Excluir registro de presença",
  "Delete voicemail": "Excluir correio de voz",
  "Delete": "Excluir",
  "description": "descrição",
  "deselect all": "Desmarcar todos",
  "Details": "Detalhes",
  "Device is offline": "O dispositivo está desconectado",
  "Devices saved successfully": "Dispositivos salvos com sucesso",
  "dial a number or drag a contact": "Disque um número ou arraste um contato",
  "Dial a number to call": "Disque um número para ligar",
  "Dial a number to initiate conference": "Disque um número para iniciar a conferência",
  "Dial a number to transfer": "Disque um número para transferir",
  "Dial": "Disque",
  "Dialog Error": "Erro de diálogo",
  "Dialpad": "Teclado",
  "directory": "diretório",
  "Directory": "Diretório",
  "disable": "desabilitar",
  "Disable": "Desativar",
  "display name": "nome",
  "display photo": "foto",
  "Do Not Disturb": "Não Perturbar",
  "Do you want to continue?": "Você quer continuar?",
  "don't have an account": "não tem uma conta",
  "Dont have an account?": "Não tem uma conta?",
  "Edit contact": "Editar Contato",
  "Edit Contact": "Editar Contato",
  "edit group": "Editar grupo",
  "edit number": "editar número",
  "Edit Presence": "Editar Presença",
  "Edit User": "Editar Usuário",
  "edit": "editar",
  "email address": "email",
  "email already exists": "E-mail já existe",
  "email": "email",
  "Email": "Email",
  "Enable": "Permitir",
  "end and accept": "desligar & aceitar",
  "English (UK)": "Inglês (Reino Unido)",
  "English (US)": "Inglês (EUA)",
  "enter a number": "Digite um número",
  "Enter account email": "Digite o e-mail da conta",
  "enter code": "digite o código",
  "Enter invite code": "Insira o código do convite",
  "Enter your new password again": "Digite sua nova senha novamente",
  "Enter your new password": "Digite sua nova senha",
  "Enter your password again": "Digite sua senha novamente",
  "Enter your password": "Digite sua senha",
  "Error on contact creating, review inputs and try again!": "Erro ao criar o contato, reveja os campos e tente novamente!",
  "Error on number creating, review inputs and try again!": "Erro ao criar o número, reveja os campos e tente novamente!",
  "Error on profile creating, review inputs and try again!": "Erro na criação do perfil, reveja os campos e tente novamente!",
  "Error on profile creating, review URL input and try again!": "Erro na criação do perfil, revise o campo URL e tente novamente!",
  "Error on user creating, review inputs and try again!": "Erro na criação do usuário, revise os campos e tente novamente!",
  "Expires": "Expira",
  "Failed to connet to the server": "Falha ao conectar ao servidor com {number}",
  "Failed to import Outlook contacts": "Falha na importação de contatos do Outlook",
  "Failed to load this voicemail": "Falha ao carregar este correio de voz",
  "Failed to save contact": "Falha ao salvar contato",
  "Failed to start the conference with": "Falha ao iniciar a conferência com {name}",
  "Failed to transfer": "Falha ao transferir",
  "Favorites": "Favoritos",
  "february": "fevereiro",
  "filter": "filtro | filtros",
  "First name": "Primeiro nome",
  "first_and_last_name": "Por favor, certifique-se de que o nome de usuário ({name}) contenha seu nome e sobrenome.",
  "Forbidden. Review your settings": "{number} Revise suas configurações",
  "Forgot Password?": "Esqueceu a senha?",
  "Forgot your password?": "Esqueceu sua senha?",
  "forgot your password": "esqueceu sua senha",
  "from date": "da data",
  "from": "de",
  "From": "De",
  "Full name": "Nome completo",
  "Giving it a try": "Tentando",
  "Groups": "Grupos",
  "Group not found": "Grupo não encontrado",
  "have an email invite": "tem um convite por e-mail",
  "hide": "Ocultar",
  "history": "histórico",
  "hold and accept": "aguardar & aceitar",
  "Hold": "Espera",
  "Home phone": "Telefone residencial",
  "home": "Residencial",
  "hostname": "hostname",
  "hour": "hora | horas",
  "Ignore": "Ignorar",
  "Image files": "Arquivos de imagem",
  "import apple": "importar apple",
  "import contacts": "importar contatos",
  "import outlook": "importar outlook",
  "imported": "importado",
  "incoming call": "chamada recebida",
  "Incoming Conference": "Conferência Recebida",
  "Incoming meeting from": "Reunião de",
  "Incompatible SDP": "SDP incompatível",
  "Invite new User": "Convidar novo Usuário",
  "invite successfully processed": "O convite foi processado com sucesso",
  "Invite to conference": "Convidar para conferência",
  "Invite": "Convidar",
  "invited": "convidado",
  "is currently being transferred": " está sendo transferido no momento",
  "it appears that you don’t have any messages": "parece que você não tem nenhuma mensagem",
  "it appears that you don’t have any voicemail": "parece que você não tem nenhum correio de voz",
  "january": "janeiro",
  "Job title": "Cargo",
  "july": "julho",
  "june": "junho",
  "label": "Etiqueta",
  "Language": "Língua | Línguas",
  "last name": "Sobrenome",
  "Lasted": "Durou",
  "Load more": "Carregar mais",
  "loading conference": "carregando conferência",
  "Loading Profile": "Carregando perfil",
  "loading": "carregando",
  "Log In": "Entrar",
  "Looks like you are missing some presence.": "Parece que está faltando alguma presença.",
  "make a call": "faça uma ligação",
  "manage numbers": "gerenciar números",
  "march": "março",
  "may": "maio",
  "Meeting with": "Reunião com",
  "Meeting": "Reunião",
  "meetings": "Reuniões",
  "message": "mensagem",
  "messages": "mensagens",
  "Microphone Audio": "Áudio do microfone",
  "microphone": "Microfone",
  "minute": "minuto | minutos",
  "missed call": "chamada perdida",
  "missed_calls": "chamadas perdidas",
  "Missed": "Perdida",
  "Mobile phone": "Número do telefone celular",
  "mobile": "móvel",
  "most recent calls": "ligações mais recentes",
  "Mute": "Mudo",
  "My Activity": "Minha atividade",
  "name": "nome | nomes",
  "Name": "Nome",
  "New contact name": "Novo nome de contato",
  "New contact number": "Novo número de contato",
  "New contact or group": "Criar novo contato ou grupo",
  "New contact": "Novo contato",
  "New group": "Novo grupo",
  "New meeting": "Nova reunião",
  "new number": "novo número",
  "new_message": "Nova mensagem",
  "next": "Próximo",
  "No ACK": "Um INVITE de entrada foi respondido com o código de status 2XX, mas nenhum ACK foi recebido",
  "No activity yet description": "Quando você iniciar ou receber chamadas, mensagens e reuniões, <br> suas atividades aparecerão aqui.",
  "No activity yet": "Ainda sem atividades",
  "No Answer": "Sem resposta",
  "no call history description": "Parece que você não tem nenhum histórico de chamada. Faça uma ligação e preencha esta seção.",
  "no call history": "sem histórico de chamadas",
  "no calls found": "nenhuma chamada encontrada",
  "No calls yet description": "Quando você inicia ou recebe chamadas, <br> elas aparecem aqui.",
  "No calls yet": "Ainda não há chamadas",
  "No camera detected": "Nenhuma câmera detectada",
  "no contacts 1": "Sem contatos",
  "no contacts description 1": "Parece que estão faltando alguns contatos.",
  "no contacts description 2": "Não se preocupe, você pode importar seus contatos clicando no botão abaixo ou criando um novo.",
  "no contacts": "nenhum contato encontrado",
  "no meeting yet": "nenhuma reunião",
  "No meetings yet description": "Quando você cria ou participa de reuniões, <br> elas aparecem aqui.",
  "No meetings yet": "Ainda não há reuniões",
  "No messages yet description": "Quando você envia ou recebe mensagens, <br> elas aparecem aqui.",
  "No messages yet": "Ainda não há mensagens",
  "No microphone detected": "Nenhum microfone detectado",
  "no new notifications": "sem novas notificações",
  "no new voicemail": "sem novo correio de voz",
  "No number selected to make a call": "Nenhum número selecionado para fazer uma chamada",
  "No numbers registered": "Nenhum número registrado",
  "no phone numbers": "sem números de telefone",
  "No presence": "Sem presença",
  "No speaker detected": "Nenhum alto-falante detectado",
  "no voicemails description": "Parece que você não tem nenhum correio de voz.",
  "No voicemails yet description": "Quando você receber correios de voz, <br> eles aparecem aqui.",
  "No voicemails yet": "Ainda sem correio de voz",
  "no voicemails": "sem correio de voz",
  "no_message_found_detailed": "Parece que você não enviou nenhuma mensagem.",
  "no_message_found": "Nenhuma mensagem encontrada",
  "No": "Não",
  "Not Found": "Não encontrado",
  "not informed": "não Informado",
  "Nothing to see here—yet!": "Nada para ver aqui—ainda!",
  "november": "novembro",
  "Number changes saved": "Alterações de número salvas",
  "Number need a description": "Número precisa de uma descrição",
  "Number nickname": "Apelido do número",
  "registered successfully": "registrado com sucesso",
  "Number unregistered": "Número {number} não registrado",
  "number": "número | números",
  "Number": "Número",
  "numbers management": "gerenciamento de números",
  "numbers nickname": "Identificação do número",
  "Numbers": "Números",
  "october": "outubro",
  "off": "Desl",
  "Offline": "Desconectado",
  "OK": "OK",
  "on": "Lig.",
  "One of the numbers has no name. Naming them is highly recommended to improve your experience.": "Um dos números não tem nome. Nomea-los é altamente recomendado para melhorar sua experiência.",
  "Ongoing": "Em andamento",
  "Online": "Ativo",
  "Open user menu": "Abrir menu do usuário",
  "or invite a team member to start.": "ou convide um membro da equipe para começar.",
  "or": "ou",
  "other": "Outro",
  "Outlook Contacts": "Contatos do Outlook",
  "outside your directory will appear here.": "fora do seu diretório aparecerão aqui.",
  "participants": "participantes",
  "password reset": "redefinição de senha",
  "password": "senha",
  "PENDING": "PENDENTE",
  "phone": "telefone | telefones",
  "Phone": "Telefone",
  "photo": "foto",
  "please enter a city": "por favor insira uma cidade",
  "please enter a company name": "por favor insira o nome da empresa",
  "please enter a full name": "por favor insira um nome completo",
  "please enter a hostname": "por favor insira um hostname",
  "please enter a last name": "por favor insira um sobrenome",
  "Please enter a name and valid email address": "Insira um nome e um endereço de e-mail válido",
  "please enter a name": "por favor insira um nome",
  "please enter a number": "por favor insira um número",
  "please enter a password": "por favor insira uma senha",
  "please enter a position": "por favor insira uma posição",
  "Please enter a valid email address": "Por favor insira um endereço de e-mail válido",
  "please enter a valid URL": "por favor insira uma URL válida",
  "please enter an address": "por favor insira um endereço",
  "please enter an URL": "por favor insira uma URL",
  "please enter the code you received with your email invite to Switch": "por favor insira o código que você recebeu com seu convite por email para o Switch",
  "Please enter your account email and we'll email you a link to reset your password": "Insira o e-mail da sua conta e enviaremos um e-mail com um link para redefinir sua senha",
  "please enter your account email and we’ll email you a link to reset your password": "por favor, digite seu e-mail de conta e nós lhe enviaremos um link para redefinir sua senha",
  "Please enter your email address and password": "Por favor, insira seu endereço de e-mail e senha",
  "Please enter your email address": "Por favor, digite seu endereço de e-mail",
  "Please enter your invite code": "Por favor insira o seu código do convite",
  "Please enter your password": "Por favor, insira sua senha",
  "Please fill a type": "Por favor, preencha um tipo",
  "Please fill the email": "Por favor, preencha o e-mail",
  "Please fill the full name": "Por favor, preencha o nome completo",
  "Please type a number": "Por favor digite um número",
  "Please, use smaller images": "Por favor, use imagens menores",
  "Portuguese": "Português",
  "Position": "Posição",
  "post": "Enviar",
  "powered by": "feito por",
  "previous": "Anterior",
  "Profile picture": "Foto do perfil",
  "Profile": "Perfil",
  "Received a request/response that should have SDP body but did not": "Recebeu uma solicitação / resposta que deveria ter corpo SDP, mas não",
  "Received From": "Recebido de",
  "Recent": "Recente",
  "reception console": "console de recepção",
  "Record": "Gravar",
  "Redirected": "Redirecionado",
  "register a number": "registrar um número",
  "register your first number": "registre seu primeiro número",
  "Registered": "Registrado",
  "Registration": "Cadastro",
  "Rejected": "Rejeitado",
  "remaining_message": "{value} caracteres restantes",
  "remember me": "lembre de mim",
  "remove number": "remover número",
  "Remove User": "Remover usuário",
  "Remove": "Remover",
  "Request Timeout": "Tempo esgotado",
  "reset password": "Redefinir senha",
  "reset your password": "redefina sua senha",
  "Retry": "Tente novamente",
  "ringing on": "chamando em",
  "Ringing": "Chamando",
  "RTP Timeout": "Tempo de RTP esgotado",
  "Save changes to apply": "Salve as alterações para aplicar",
  "Save changes to remove number": "Salve as alterações para remover o número",
  "save": "salvar",
  "Save": "salvar",
  "search n contacts": "pesquisar {value} contato | pesquisar {value} contatos",
  "Search n messages": "Pesquisar {value} mensagens",
  "Search...": "Procurar...",
  "Search": "Procurar",
  "searching": "procurando",
  "second": "segundo | segundos",
  "Select a microphone": "Selecione um microfone",
  "select a number to view previous calls and voicemail": "selecione um número para ver as chamadas anteriores e o correio de voz",
  "select a number": "selecione um número",
  "Select a speaker": "Selecione um alto-falante",
  "select account": "Selecionar conta",
  "Select all": "Selecionar todos",
  "Select an camera": "Selecione uma câmera",
  "Select an microphone": "Selecione um microfone",
  "Select an speaker": "Selecione um alto-falante",
  "Selected": "Selecionado",
  "Send": "Enviar",
  "september": "setembro",
  "set and update your current settings": "defina e atualize suas configurações atuais",
  "Set default": "Padrão",
  "Settings": "Configurações",
  "sign in to your {host} account": "faça login em sua conta {host}",
  "Sign in to your account": "Faça login em sua conta",
  "sign in": "entrar",
  "Sign Out": "Sair",
  "signed in as": "logado como",
  "Something went wrong. Check your connection and try again.": "Algo deu errado. Verifique sua conexão e tente novamente.",
  "Spanish": "Espanhol",
  "Speaker": "Alto-falante",
  "Starred": "Favoritos",
  "State": "Estado",
  "Status": "Status",
  "Stop record": "Parar gravação",
  "Stop": "Parar",
  "Support": "Suporte",
  "Teams": "Equipes",
  "Terminated": "Terminado",
  "Test speaker": "Testar auto-falante",
  "The number has been unlinked to the user.": "O número foi desvinculado do usuário.",
  "There was an error, try again": "Ocorreu um erro, tente novamente",
  "This action can't be undone.": "Esta ação não pode ser desfeita.",
  "this phone number wont recevie phone calls": "este número de telefone não receberá chamadas telefônicas",
  "this phone will be the default option to connect": "este telefone será a opção padrão para conectar",
  "this section gives you the ability to edit your phone number": "esta seção permite editar o seu número de telefone.",
  "Time": "Tempo",
  "to date": "até a data",
  "To": "Para",
  "Today": "Hoje",
  "token expired, sign in again": "Token expirou, faça o login novamente",
  "transfer accepted": "Transferência aceita",
  "Transfer now": "Transferir agora",
  "Transfer": "Transferir",
  "Type": "Tipo",
  "Unassign number": "Cancelar atribuição de número",
  "Unassign": "Cancelar atribuição",
  "Unauthorized. Check hostname and password": "Não autorizado {number}. Verifique o host e a senha",
  "Unavailable": "Indisponível",
  "Unhold": "Liberar",
  "Unmute": "Ativar",
  "Unnamed number": "Número sem nome",
  "Unregistered": "Não registrado",
  "URL": "URL",
  "User Denied Media Access": "Acesso à mídia negado pelo usuário",
  "User removed.": "Usuário removido.",
  "User saved": "Usuário salvo",
  "User type": "Tipo de usuário",
  "user": "usuário | usuários",
  "Users management": "Gerenciamento de usuários",
  "Using as": "Usando como",
  "verify": "verificar",
  "Version": "Versão",
  "video_call_from": "Chamada de Video {name} {number}",
  "video": "vídeo",
  "view detail": "ver detalhe",
  "view details": "Ver detalhes",
  "Voicemail": "Correio de voz",
  "we don't recognize this device. Please check your email to authorize your access and try to sign in again": "não reconhecemos este dispositivo. Verifique seu e-mail para autorizar seu acesso e tente fazer login novamente",
  "Webcam Preview": "Visualização da webcam",
  "Welcome to": "Bem-vindo ao",
  "welcome": "bem-vindo",
  "work": "Trabalho",
  "Workspaces": "Espaços de Trabalho",
  "would like to start a meeting with you!": "gostaria de iniciar uma reunião com você!",
  "write_a_message_to": "Digite uma mensagem para {name}",
  "write_a_message": "Digite uma mensagem...",
  "Yes": "Sim",
  "Yesterday": "Ontem",
  "You are about to remove": "Você está prestes a remover",
  "You are not connected": "Você não está conectado",
  "You need to have a number registered in order to make a call": "É necessário ter um número registado para poder fazer uma chamada",
  "You will  receive alerts for incoming calls for the number": "Você receberá alertas de chamadas recebidas para o número",
  "You will not receive alerts for incoming calls for the number": "Você não receberá alertas de chamadas recebidas para o número",
  "you won't be notified of incoming calls": "você não será notificado de chamadas recebidas",
  "you": "você",
  "Your changes were ignored": "Suas alterações foram ignoradas",
  "your email or password is incorrect": "Seu e-mail ou senha estão incorretos",
  "your number": "Seu número",
  "your password was successfully changed": "sua senha foi alterada com sucesso",
  "your password": "Sua senha",
  "your passwords are different": "suas senhas são diferentes",
  "Select an option": "Selecione uma opção",
  "Favorite": "Favorito",
  "This is the start of something new.": "Este é o começo de algo novo.",
  "Your messages, calls, and video meetings": "Suas mensagens, chamadas e videoconferências",
  "will appear here.": "aparecerão aqui.",
  "Info": "Info",
  "Type your message here": "Digite sua mensagem aqui",
  "Users": "Usuários",
  "User edited successfully.": "Usuário editado com sucesso.",
  "User invited.": "Usuário convidado.",
  "enabled": "ativado",
  "Changed password": "Senha alterada",
  "Password are required": "Senha é necessária",
  "Confirm password are required": "Confirme a senha é necessária",
  "Password does not match": "Senha não corresponde",
  "Password requires a lowercase character": "A senha requer um caractere minúsculo",
  "Password requires a uppercase character": "A senha requer um caractere maiúsculo",
  "Password requires one digit": "A senha requer um dígito",
  "Password requires at least 8 digits": "A senha requer pelo menos 8 dígitos",
  "Create Password": "Criar senha",
  "Home Page": "Pagina inicial",
  "Login": "Login",
  "Dial a number to transfer to": "Disque um número para transferir",
  "has been unregistered": "perdeu o registro",
  "Dial a number to make the call": "Disque um número para fazer a chamada",
  "registration failed": "registração falhou",
  "No active number": "Nenhum número ativo",
  "actived": "ativado",
  "Profile updated": "Perfil atualizado",
  "disabled": "desativado",
  "Ended": "Terminou",
  "lasted": "durou",
  "You don’t have any": "Você não tem",
  "contacts in your":"nenhum contato",
  "directory. Add contacts": "em seu Diretório. Adicione",
  "to your team.": "contatos à sua Equipe.",
  "Group successfully created.": "Grupo criado com sucesso.",
  "new-message-group": "Nova mensagem em {group}",
  "new-message-chat": "Nova mensagem de {user}",
  "{name} is not a {appName} user.": "{name} não é um usuário do {appName}.",
  "Invite {name} to join your team": "Convide {name} para se juntar à sua equipe",
  "to enable messaging and video meetings.": "para permitir mensagens e videoconferências."
}
