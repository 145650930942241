import { defineStore } from 'pinia';

interface UseDarkModeStore {
  darkMode: 'light' | 'dark' | 'system';
  themeAg: 'ag-theme-alpine-dark' | 'ag-theme-alpine';
}

export const useDarkModeStore = defineStore('dark-mode', {
  state: (): UseDarkModeStore => ({
    darkMode: 'light',
    themeAg: 'ag-theme-alpine',
  }),
  actions: {
    darkModeIsLight() {
      this.darkMode = 'light';
      this.removeDarkClass();
      this.themeAg = 'ag-theme-alpine';
    },
    darkModeIsDark() {
      this.darkMode = 'dark';
      this.addDarkClass();
      this.themeAg = 'ag-theme-alpine-dark';
    },
    darkModePrefersColorScheme() {
      const prefersColorScheme = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      this.darkMode = 'system';
      if (prefersColorScheme) {
        this.addDarkClass();
      } else {
        this.removeDarkClass();
      }
    },
    addDarkClass() {
      document.body.classList.add('dark');
    },
    removeDarkClass() {
      document.body.classList.remove('dark');
    },
    hasDarkMode() {
      if (this.darkMode === 'system') {
        this.darkModePrefersColorScheme();
      } else {
        if (this.darkMode === 'light') {
          this.darkModeIsLight();
        } else {
          this.darkModeIsDark();
        }
      }
    },
  },
  persist: true,
});
