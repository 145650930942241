import { defineStore } from 'pinia';
import type {
  Directory,
  DirectoryAvatars,
  DirectoryIndex,
  DirectoryUnReadMessages,
  GroupUnReadMessages,
  IndexesStore,
  RegisteredNumbers,
} from '@/types';
import { useUserStore } from '@/stores/user';
import { convertBlobToBase64 } from '@/utils';
import { increaseBadge, decreaseBadge } from '@/composables/use-notification';

export const useIndexesStore = defineStore('indexes', {
  state: (): IndexesStore => ({
    directories: {},
    unreadDirectoryMessages: {},
    unreadGroupMessages: {},
    scrollLock: true,
    avatars: {},
    registeredNumbers: {},
  }),
  actions: {
    setDirectoryIndex(directory: Directory) {
      const directories: DirectoryIndex = this.directories;
      directories[directory.userId] = directory;
      this.directories = { ...directories };
    },
    setUnreadDirectoryMessage(userId: number, qty = 0) {
      const unreadMessages: DirectoryUnReadMessages =
        this.unreadDirectoryMessages;

      if (!Object.prototype.hasOwnProperty.call(unreadMessages, userId)) {
        unreadMessages[userId] = 0;
      }

      const qtyUnread = qty > 0 ? qty : 1;
      unreadMessages[userId] = unreadMessages[userId] + qtyUnread;
      this.unreadDirectoryMessages = { ...unreadMessages };
      increaseBadge(qtyUnread);
    },

    setUnreadGroupMessage({
      unreadGroupMessages,
      groupId,
      qty = 0,
    }: {
      unreadGroupMessages?: GroupUnReadMessages;
      groupId?: number;
      qty?: number;
    }) {
      if (groupId && qty) {
        this.unreadGroupMessages[groupId] =
          (this.unreadGroupMessages[groupId] ?? 0) + qty;
        increaseBadge(qty);
      }

      if (unreadGroupMessages) {
        increaseBadge(Object.values(unreadGroupMessages).reduce((a, b) => a + b, 0));
        this.unreadGroupMessages = { ...unreadGroupMessages };
      }
    },

    clearUnreadGroupMessage(groupId: number) {
      decreaseBadge(this.unreadGroupMessages[groupId]);
      this.unreadGroupMessages[groupId] = 0;
    },

    clearUnreadMessage(userId: number) {
      const unreadMessages: DirectoryUnReadMessages =
        this.unreadDirectoryMessages;

      decreaseBadge(unreadMessages[userId]);
      unreadMessages[userId] = 0;
      this.unreadDirectoryMessages = { ...unreadMessages };
    },
    async setAvatarImage(userId: number) {
      const userStore = useUserStore();
      const avatars: DirectoryAvatars = this.avatars;

      avatars[userId] = {};

      for (const size of [20, 32, 80]) {
        try {
          this.getImageFromPath(
            `${
              userStore.publicStorage
            }/avatar/${userId}_${size}x${size}.jpg?${Date.now()}`,
          )
            .then(async (blob) => {
              const img = await convertBlobToBase64(blob as Blob);
              if (typeof img === 'string') {
                avatars[userId][size] = img;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.error({ err });
        }
      }

      this.avatars = { ...avatars };
    },
    getImageFromPath(imagePath: string) {
      return new Promise((resolve, reject) => {
        fetch(imagePath)
          .then((response) => {
            if (!response.ok || response.status === 204) {
              throw new Error(`Failed to fetch image: ${imagePath}`);
            }
            return response.blob();
          })
          .then((blob) => resolve(blob))
          .catch((error) => reject(error));
      });
    },
    updateNumberRegistering(number: string, active: boolean) {
      const numberRegistering: RegisteredNumbers = this.registeredNumbers;

      numberRegistering[number] = active;
      this.registeredNumbers = { ...numberRegistering };
    },
    enableScrollLock() {
      this.scrollLock = true;
    },
    disableScrollLock() {
      this.scrollLock = false;
    },
  },
  persist: false,
});
