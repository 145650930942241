import Swal from 'sweetalert2';
import type { Router, RouteLocationRaw } from 'vue-router';

export const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  showCloseButton: true,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export function toastSuccess(message: string) {
  Toast.fire({
    icon: 'success',
    title: message,
  });
}

export function toastError(message: string) {
  Toast.fire({
    icon: 'error',
    title: message,
  });
}

export function toastInfo(message: string) {
  Toast.fire({
    icon: 'info',
    title: message,
  });
}

export function toastWarning(message: string) {
  Toast.fire({
    icon: 'warning',
    title: message,
  });
}

export function toastMessages(
  message: string,
  title: string,
  url: RouteLocationRaw,
  router: Router
) {
  Toast.fire({
    title: title,
    text: message,
    customClass: {
      htmlContainer: 'm-0',
      title: 'm-0',
      container: 'cursor-pointer',
    },
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
      toast.addEventListener('click', (event) => {
        const target = event.target as Element;
        if (!target.classList.contains('swal2-close')) {
          router.push(url);
          toast.remove();
        }
      });
    },
  });
}

export function toastConfirmation(
  title: string,
  message: string,
  confirmCallback: () => void,
  declineCallback: () => void,
  timerProgressBar = true,
  timer = 10000
) {
  Toast.fire({
    showCloseButton: false,
    showConfirmButton: true,
    showCancelButton: true,
    icon: 'info',
    title: title,
    text: message,
    confirmButtonText: 'Accept',
    cancelButtonText: 'Decline',
    timer: timer,
    timerProgressBar: timerProgressBar,
  }).then((result) => {
    return result.isConfirmed ? confirmCallback() : declineCallback();
  });
}
